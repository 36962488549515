import React, { useMemo } from "react";
import store from "../../../MobX";
import { observer } from "mobx-react-lite";

const _getChangeProductInfoCell = ({
  oldSalePrice,
  pricesaleretail,
  isUnavailable,
}) => {
  if (isUnavailable) {
    return (
      <td colSpan="2">
        <span className="table__str-title red-bolt">Товар раскупили</span>
      </td>
    );
  }

  return (
    <>
      <td className="center-align">
        <span
          className="table__str-title"
          style={{
            textDecoration: "line-through",
          }}
        >
          {oldSalePrice?.toLocaleString()} ₽
        </span>
      </td>
      <td className="center-align">
        <span className="table__str-title red-bolt">
          {pricesaleretail?.toLocaleString()} ₽
        </span>
      </td>
    </>
  );
};

const ChangeCartProducts = observer(() => {
  const { modalData } = store;

  const { productsChange, buttonText = "Понятно", isDanger } = modalData;

  const onSubmit = () => {
    console.log("clock");
    store.showModal = false;
    store.isModalType = {};
    store.modalData = {};
  };

  const [productsWithChangePrice, unavailableProducts] = useMemo(
    () =>
      productsChange.reduce(
        (acc, product) => {
          if (product.isUnavailable) {
            acc[1].push(product);
          } else {
            acc[0].push(product);
          }
          return acc;
        },
        [[], []]
      ),
    [productsChange]
  );

  return (
    <div>
      <p className="authorization-modal__activation-info authorization-modal__activation-info_message">
        Заказ не был оформлен, так как цена некоторых товаров изменилась.
      </p>
      <div>
        <table className="table">
          <thead className="thead">
            <tr>
              <th>Позиция</th>
              <th className="center-align">Старая цена</th>
              <th className="center-align">Новая цена</th>
            </tr>
          </thead>
          <tbody className="tbody">
            {[...productsWithChangePrice, ...unavailableProducts].map(
              (item) => {
                const {
                  cardname,
                  productcode,
                  oldSalePrice,
                  pricesaleretail,
                  isUnavailable,
                } = item;

                const colls = _getChangeProductInfoCell({
                  oldSalePrice,
                  pricesaleretail,
                  isUnavailable,
                });

                return (
                  <tr key={productcode}>
                    <td>
                      <span
                        className="table__str-title"
                        style={
                          isUnavailable
                            ? {
                                textDecoration: "line-through",
                              }
                            : {}
                        }
                      >
                        {cardname} ({productcode})
                      </span>
                    </td>
                    {colls}
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
      {/*<p className="authorization-modal__activation-info authorization-modal__activation-info_message">*/}
      {/*  {message || ""}*/}
      {/*</p>*/}

      <button
        className={`btn btn_primary`}
        onClick={onSubmit}
        style={{ width: "100%" }}
      >
        {buttonText}
      </button>
    </div>
  );
});

export default ChangeCartProducts;
